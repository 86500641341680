
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EsService } from '@/services/es.service';
import { JnService } from '@/services/jn.service';
import JnProgress from '@/views/common/Progress.vue';
import JnAutocompleteSearch from '@/views/common/Autocomplete.vue';
import { Picklist } from '@/models/picklist.model';
import { ValidationObserver } from 'vee-validate';
import { SearchData } from '@/models/search.model';
import EventBus from '@/services/event.bus';
import {
  SearchTypes,
  Categories,
  CategoriesJuranews,
  CategoriesGiurisprudenza,
  CategoriesPrassiJuranet,
  CategoriesNormativa,
  CategoriesMassimario,
  CategoriesFormulari,
} from '@/models/enums.model';

@Component({
  components: {
    JnProgress,
    ValidationObserver,
    JnAutocompleteSearch,
  },
})
export default class JnGlobalSearch extends Vue {
  searchData = new SearchData({
    index: process.env.VUE_APP_ALL_INDEXES!,
    keywords: [],
    size: 1000,
    from: 0,
    searchScope: 'general',
  });

  optText1 = '';
  optText2 = '';
  showText2 = false;
  optText3 = '';
  showText3 = false;
  optType = 'AND';
  optArea = SearchTypes.ALL;
  optTipoOrdinamento?: Picklist;
  optNumRisultati?: Picklist;

  tipiQuery: Picklist[] = [];
  tipiOrdinamento: Picklist[] = [];
  numRisultati: Picklist[] = [];
  aree: Picklist[] = [];

  alert = false;
  alertMessage = '';
  alertTimeout = 6000;

  advanced = true;
  loading = false;

  /* @Watch('optText')
  changeTipoOrdinamento() {
    this.optTipoOrdinamento = this.tipiOrdinamento[0];
  }*/

  async switchAdvanced() {
    this.advanced = !this.advanced;
  }

  async checkAndSearch() {
    EventBus.$emit('check-spell', SearchTypes.ALL);
  }

  addTextSearch() {
    if (!this.showText2) {
      this.showText2 = true;
    } else if (!this.showText3) {
      this.showText3 = true;
    }
  }
  removeTextSearch2() {
    this.showText2 = false;
  }
  removeTextSearch3() {
    this.showText3 = false;
  }

  async search() {
    this.showSearchBancaDati = false;
    this.searchNormativaSelected = false;
    this.searchGiurisprudenzaSelected = false;
    this.searchPrassiDecisioniSelected = false;
    this.searchData.globalSearch = false;

    const optText1 = this.optText1
        ? this.optText1.replaceAll('/', '\\/')
        : this.optText1;
    const optText2 = this.showText2 && this.optText2
      ? this.optText2.replaceAll('/', '\\/')
      : "";
    const optText3 = this.showText3 && this.optText3
      ? this.optText3.replaceAll('/', '\\/')
      : "";
    this.searchData.keywords = []
    if (optText1 != "") {
      this.searchData.keywords.push(optText1)
    }
    if (this.showText2 && optText2 != "") {
      this.searchData.keywords.push(optText2)
    }
    if (this.showText3 && optText3 != "") {
      this.searchData.keywords.push(optText3)
    }
   
    this.searchData.queryType = this.optType;
    if (this.optTipoOrdinamento) {
      this.searchData.orderingType = this.optTipoOrdinamento.value;
    }
    if (this.optNumRisultati) {
      this.searchData.perPage = Number(this.optNumRisultati.value);
    }

    if (this.optArea == SearchTypes.ALL) {
      this.searchData.index = process.env.VUE_APP_ALL_INDEXES!;
      this.searchData.idCategory = CategoriesJuranews.concat(
        CategoriesPrassiJuranet
      )
        .concat(CategoriesGiurisprudenza)
        .concat(CategoriesNormativa)
        .concat(CategoriesMassimario);
    } else if (this.optArea == SearchTypes.NEWS) {
      this.searchData.index = process.env.VUE_APP_JURANEWS_INDEXES!;
      this.searchData.idCategory = [Categories.ULTIME_NEWS];
    } else if (this.optArea == SearchTypes.AREE_TEMATICHE) {
      this.searchData.index = process.env.VUE_APP_JURANEWS_INDEXES!;
      this.searchData.idCategory = [
        Categories.COMMENTI,
        Categories.SCHEDA_AUTORALE,
        Categories.CONCORSI,
        Categories.SINTESI_GIURISPRUDENZIALI,
      ];
    } else if (this.optArea == SearchTypes.BANCA_DATI) {
      this.searchData.index = process.env.VUE_APP_JURANET_INDEXES!;
      this.searchData.idCategory = [];
    }
    else if (this.optArea == SearchTypes.MASSIMARIO) {
      this.searchData.idCategory = [];
      this.searchData.index = process.env.VUE_APP_JURANEWS_INDEXES!;
      for (const massimaCategory of CategoriesMassimario) {
        this.searchData.idCategory?.push(massimaCategory)
      }
    }
    else if (this.optArea == SearchTypes.FORMULARI) {
      this.searchData.idCategory = [];
      this.searchData.index = process.env.VUE_APP_JURANEWS_INDEXES!;
      for (const formularioCategory of CategoriesFormulari) {
        this.searchData.idCategory?.push(formularioCategory)
      }
    }
    /*    console.log(
          'JnGlobalSearch.search(searchData): ' +
          JSON.stringify(this.searchData, null, 4)
        );*/
    this.loading = true;
    const docs = await EsService.getDocs(this.searchData);
    if (docs.length == 0) {
      this.alert = true;
      this.alertMessage = 'Nessun risultato trovato';
    }
    this.loading = false;
    EventBus.$emit('search-data', this.searchData);
    EventBus.$emit('search-results', docs);

    this.$store.commit('searchData', this.searchData);
  }

  clear() {
    this.optText1 = '';
    this.optText2 = '';
    this.optText3 = '';
    this.showText2 = false;
    this.showText3 = false;
    this.optType = 'AND';
    this.optArea = SearchTypes.ALL;
    this.optTipoOrdinamento = this.tipiOrdinamento[0];
    this.optNumRisultati = this.numRisultati[0];

    this.showSearchAmbitiJuranews = false;
    this.showSearchBancaDati = false;
    this.searchNormativaSelected = false;
    this.searchGiurisprudenzaSelected = false;
    this.searchPrassiDecisioniSelected = false;
  }

  close() {
    this.clear();
    this.advanced = false;
  }

  async created() {
    EventBus.$on('reset-ricerca-global', () => {
      this.clear();
    });
    EventBus.$on('select-text', (suggestion: any) => {
      if (suggestion.searchScope == SearchTypes.ALL) {
        if (suggestion.index == 0) {
          this.optText1 = suggestion.suggestion;
        } else if (suggestion.index == 1) {
          this.optText2 = suggestion.suggestion;
        } else if (suggestion.index == 2) {
          this.optText3 = suggestion.suggestion;
        }
      }
    });
    EventBus.$on('search', (search: any) => {
      console.log(search)
      if (search.searchScope == SearchTypes.ALL && search.keywords && search.keywords.length > 0) {
        if (search.index == 0) {
          this.optText1 = search.keywords;
        }
        if (search.index == 1) {
          this.optText2 = search.keywords;
        }
        if (search.index == 2) {
          this.optText3 = search.keywords;
        }
        this.search();
      }
    });
    EventBus.$on('search-data', async (searchData: SearchData) => {
      if (searchData) {
        const newSearchData = searchData.clone();
        if (
          newSearchData &&
          newSearchData.searchScope &&
          newSearchData.searchScope == 'general'
        ) {
          if (!newSearchData!.globalSearch) {
            if (newSearchData!.index == process.env.VUE_APP_ALL_INDEXES) {
              this.newsResultCount(newSearchData);
              this.areeTematicheResultCount(newSearchData);
              this.bancaDatiResultCount(newSearchData);
              this.showSearchAmbitiJuranews = true;
              this.showSearchBancaDati = false;
            } else if (
              newSearchData!.index.includes(process.env.VUE_APP_JURANET_INDEXES!)
            ) {
              this.normativaResultCount(newSearchData);
              this.giurisprudenzaResultCount(newSearchData);
              this.prassiResultCount(newSearchData);
              this.showSearchAmbitiJuranews = false;
              this.showSearchBancaDati = true;
            } else {
              this.showSearchAmbitiJuranews = false;
              this.showSearchBancaDati = false;
            }
          } else if (
            this.showSearchAmbitiJuranews &&
            newSearchData!.index.includes(process.env.VUE_APP_JURANET_INDEXES!)
          ) {
            this.normativaResultCount(newSearchData);
            this.giurisprudenzaResultCount(newSearchData);
            this.prassiResultCount(newSearchData);
          }
        }
      }
    });
    this.tipiQuery = JnService.getQueryTypes();
    this.tipiOrdinamento = JnService.getTipiOrdinamento();
    this.numRisultati = JnService.getNumeroRisultati();
    this.aree = JnService.getTipiRicercaGlobale();
    this.clear();

    this.showSearchBancaDati = false;
    this.searchNormativaSelected = false;
    this.searchGiurisprudenzaSelected = false;
    this.searchPrassiDecisioniSelected = false;
  }

  /**
   * Partial Search
   */
  showSearchAmbitiJuranews = false;
  totalResultsNews = 0;
  totalResultsAreeTematiche = 0;
  totalResultsBancaDati = 0;

  showSearchBancaDati = false;
  totalResultsNormativa = 0;
  totalResultsGiurisprudenza = 0;
  totalResultsPrassiDecisioni = 0;

  searchNewsSelected = false;
  searchAreeTematicheSelected = false;
  searchBancaDatiSelected = false;
  searchNormativaSelected = false;
  searchGiurisprudenzaSelected = false;
  searchPrassiDecisioniSelected = false;

  async newsResultCount(searchData: SearchData) {
    searchData!.index = process.env.VUE_APP_JURANEWS_INDEXES!;
    searchData!.idCategory = [Categories.ULTIME_NEWS];
    this.totalResultsNews = await EsService.countDocs(searchData!);
  }

  async searchNews() {
    this.loading = true;
    this.searchData!.index = process.env.VUE_APP_JURANEWS_INDEXES!;
    this.searchData!.idCategory = [Categories.ULTIME_NEWS];
    this.searchData!.globalSearch = true;
    this.searchData!.orderingType = this.tipiOrdinamento[1].value;
    const docs = await EsService.getDocs(this.searchData!);
    if (docs && docs.length == 0) {
      EventBus.$emit('search-results', []);
    } else {
      EventBus.$emit('search-data', this.searchData);
      EventBus.$emit('search-results', docs);
    }
    this.searchNewsSelected = true;
    this.searchAreeTematicheSelected = false;
    this.searchBancaDatiSelected = false;
    this.searchNormativaSelected = false;
    this.searchGiurisprudenzaSelected = false;
    this.searchPrassiDecisioniSelected = false;
    this.showSearchBancaDati = false;
    this.loading = false;
  }

  async areeTematicheResultCount(searchData: SearchData) {
    searchData!.index = process.env.VUE_APP_JURANEWS_INDEXES!;
    searchData!.idCategory = [
      Categories.COMMENTI,
      Categories.SCHEDA_AUTORALE,
      Categories.CONCORSI,
      Categories.SINTESI_GIURISPRUDENZIALI,
    ];
    this.totalResultsAreeTematiche = await EsService.countDocs(searchData!);
  }

  async searchAreeTematiche() {
    this.loading = true;
    this.searchData!.index = process.env.VUE_APP_JURANEWS_INDEXES!;
    this.searchData!.idCategory = [
      Categories.COMMENTI,
      Categories.SCHEDA_AUTORALE,
      Categories.CONCORSI,
      Categories.SINTESI_GIURISPRUDENZIALI,
    ];
    this.searchData!.globalSearch = true;
    this.searchData!.orderingType = this.tipiOrdinamento[1].value;
    const docs = await EsService.getDocs(this.searchData!);
    if (docs && docs.length == 0) {
      EventBus.$emit('search-results', []);
    } else {
      EventBus.$emit('search-data', this.searchData);
      EventBus.$emit('search-results', docs);
    }
    this.searchNewsSelected = false;
    this.searchAreeTematicheSelected = true;
    this.searchBancaDatiSelected = false;
    this.searchNormativaSelected = false;
    this.searchGiurisprudenzaSelected = false;
    this.searchPrassiDecisioniSelected = false;
    this.showSearchBancaDati = false;
    this.loading = false;
  }

  async bancaDatiResultCount(searchData: SearchData) {
    searchData!.index = process.env.VUE_APP_JURANET_INDEXES!;
    searchData!.idCategory = CategoriesNormativa.concat(
      CategoriesGiurisprudenza
    ).concat(CategoriesPrassiJuranet);
    this.totalResultsBancaDati = await EsService.countDocs(searchData!);
  }
  async searchBancaDati() {
    this.loading = true;
    this.searchData!.index = process.env.VUE_APP_JURANET_INDEXES!;
    this.searchData!.idCategory = CategoriesNormativa.concat(
      CategoriesGiurisprudenza
    ).concat(CategoriesPrassiJuranet);
    this.searchData!.globalSearch = true;
    this.searchData!.orderingType = this.tipiOrdinamento[1].value;
    const docs = await EsService.getDocs(this.searchData!);
    if (docs && docs.length == 0) {
      EventBus.$emit('search-results', []);
    } else {
      EventBus.$emit('search-data', this.searchData);
      EventBus.$emit('search-results', docs);
    }
    this.searchNewsSelected = false;
    this.searchAreeTematicheSelected = false;
    this.searchBancaDatiSelected = true;
    this.searchNormativaSelected = false;
    this.searchGiurisprudenzaSelected = false;
    this.searchPrassiDecisioniSelected = false;
    this.showSearchBancaDati = true;
    this.loading = false;
  }

  async normativaResultCount(searchData: SearchData) {
    searchData!.index = process.env.VUE_APP_JURANET_INDEXES!;
    searchData!.idCategory = CategoriesNormativa;
    this.totalResultsNormativa = await EsService.countDocs(searchData!);
  }

  async searchNormativa() {
    this.loading = true;
    this.searchData!.index = process.env.VUE_APP_JURANET_INDEXES!;
    this.searchData!.idCategory = CategoriesNormativa;
    this.searchData!.globalSearch = true;
    this.searchData!.orderingType = this.tipiOrdinamento[1].value;
    const docs = await EsService.getDocs(this.searchData!);
    if (docs && docs.length == 0) {
      EventBus.$emit('search-results', []);
    } else {
      EventBus.$emit('search-data', this.searchData);
      EventBus.$emit('search-results', docs);
    }
    this.searchNormativaSelected = true;
    this.searchGiurisprudenzaSelected = false;
    this.searchPrassiDecisioniSelected = false;
    this.loading = false;
  }

  async giurisprudenzaResultCount(searchData: SearchData) {
    searchData!.index = process.env.VUE_APP_JURANET_INDEXES!;
    searchData!.idCategory = CategoriesGiurisprudenza;
    this.totalResultsGiurisprudenza = await EsService.countDocs(searchData!);
  }
  async searchGiurisprudenza() {
    this.loading = true;
    this.searchData!.index = process.env.VUE_APP_JURANET_INDEXES!;
    this.searchData!.idCategory = CategoriesGiurisprudenza;
    this.searchData!.globalSearch = true;
    this.searchData!.orderingType = this.tipiOrdinamento[1].value;
    const docs = await EsService.getDocs(this.searchData!);
    if (docs && docs.length == 0) {
      EventBus.$emit('search-results', []);
    } else {
      EventBus.$emit('search-data', this.searchData);
      EventBus.$emit('search-results', docs);
    }
    this.searchNormativaSelected = false;
    this.searchGiurisprudenzaSelected = true;
    this.searchPrassiDecisioniSelected = false;
    this.loading = false;
  }

  async prassiResultCount(searchData: SearchData) {
    searchData!.index = process.env.VUE_APP_JURANET_INDEXES!;
    searchData!.idCategory = CategoriesPrassiJuranet;
    this.totalResultsPrassiDecisioni = await EsService.countDocs(searchData!);
  }
  async searchPrassiDecisioni() {
    this.loading = true;
    this.searchData!.index = process.env.VUE_APP_JURANET_INDEXES!;
    this.searchData!.idCategory = CategoriesPrassiJuranet;
    this.searchData!.globalSearch = true;
    this.searchData!.orderingType = this.tipiOrdinamento[1].value;
    const docs = await EsService.getDocs(this.searchData!);
    if (docs && docs.length == 0) {
      EventBus.$emit('search-results', []);
    } else {
      EventBus.$emit('search-data', this.searchData);
      EventBus.$emit('search-results', docs);
    }
    this.searchNormativaSelected = false;
    this.searchGiurisprudenzaSelected = false;
    this.searchPrassiDecisioniSelected = true;
    this.loading = false;
  }
}
