
import JnGlobalSearch from '@/views/search/GlobalSearch.vue';
import JnCardsStrip from '@/views/common/CardsStrip.vue';
import JnSearchResults from '@/views/search/SearchResults.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { JnService } from '@/services/jn.service';
import { SearchData } from '@/models/search.model';
import { Sections } from '@/models/enums.model';
import { Testata } from '@/models/testata.model';

@Component({
  components: {
    JnGlobalSearch,
    JnCardsStrip,
    JnSearchResults,
  },
})
export default class JnGeneralSearch extends Vue {
  testata?: Testata;

  /*searchData = new SearchData({
    index: process.env.VUE_APP_ALL_INDEXES!,
    keywords: '',
    size: 1000,
    from: 0,
    searchScope: 'general',
  });

  inEvidenzaSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    featured: 1,
  });
*/
  created() {
    this.testata = JnService.getTestata(Sections.COMMON_SEARCH_RESULT);
  }
}
